@charset "utf-8";
/* CSS Document */

html{
    height:100%;
}
body {
    font-size: 13px;
    font-family: Tahoma, Arial, sans-serif;
    margin: 0px;
    padding: 0px;
    color: #2a2a2a;
    line-height: 19px;
    background: #fafafa;
    background: -moz-linear-gradient(top,  #fafafa 0%, #e4e4e4 100%);
    background: -webkit-linear-gradient(top,  #fafafa 0%,#e4e4e4 100%);
    background: linear-gradient(to bottom,  #fafafa 0%,#e4e4e4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#e4e4e4',GradientType=0 );
    background-attachment:fixed;
    background:url(/images/bodybgr.png) repeat;
}
* {
    outline: medium none;
}
::-webkit-input-placeholder {
   color: #AAA;
}
:-moz-placeholder {
   color: #666;
}
::-moz-placeholder {
   color: #666;
}
:-ms-input-placeholder {
   color: #666;
}
select{
    font-family: Tahoma, Arial, sans-serif;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
input[type="text"],
input[type="password"]  ,input[type="email"], input[type="tel"] {
    font-family: Tahoma, Arial, sans-serif;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding:0;
    margin:0;
}
textarea{
    font-family: Tahoma, Arial, sans-serif;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding:0;
    margin:0;
}
ul {
    list-style:none;
    display:inline-block;
    width:100%;
    margin:0;
    padding:0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
li {
    float:left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
a, [data-link] {
    display:inline-block;
    text-decoration:underline;
    color:#004880;
}

a:hover {
    text-decoration:none;
}
button{
    cursor: pointer;
    display: inline-block;
    width: 100%;
    line-height: 36px;
    margin: 0;
    background: #164775;
    border: 1px solid #164775;
    color: #FFFFFF;
    font-size:15px;
    font-weight: bold;
    text-transform:uppercase;
    padding: 0 15px ;
    border-radius: 4px 4px 4px 4px;
}
button:active{
    background: #22578c;
    border: 1px solid #22578c;
}
button.orange {
    background: #ff8800;
    border: 1px solid #ff8800;
}
button.orange:active {
    background: #ff9900;
    border: 1px solid #ff9900;
}
img {
    border:none;
}
button img{
    margin:0 5px 0 0;
    vertical-align:middle;
}
.green{ color:#179fda !important; }

.sticky_banner {
    position: sticky;
    top: 10px;
}

.header {
    position:relative;
    z-index:9999999;
    font-size: 13px;
    box-shadow:0 2px 3px rgba(0,0,0,0.20);
}
.container{
    position:relative;
    width:980px;
    margin:0 auto;
}
.header .menu{
    display:block;
    height:46px;
    display: block;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #0099d8), color-stop(100%, #267ec8));
    background: -webkit-linear-gradient(#0099d8, #267ec8);
    background: -moz-linear-gradient(#0099d8, #267ec8);
    background: -o-linear-gradient(#0099d8, #267ec8);
    background: linear-gradient(#0099d8, #267ec8);
    background-color: #267ec8;
}
.header .rightMenu{
    position:absolute;
    top:0px;
    right:0;
}
.header .mainMenu {
    position:absolute;
    top:20px;
    right:0;
}
.header .mainMenu a, .header .mainMenu span {
    display:inline-block;
    color: #828282;
    font-size:16px;
    font-weight: bold;
    line-height: 46px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
}
.header .mainMenu a:hover,
.header .mainMenu a.current,
.header .mainMenu span:hover,
.header .mainMenu span.current
{
    color:#000;
}
.header .mainMenu a.selected, .header .mainMenu span.selected {
    color:#004880;
}
.header .mainMenu a.home{
    width:42px;
    height:26px;
    background:url(/images/ico/home.png) 10px 0 no-repeat;
    vertical-align:middle;
    transition: all .3s;
}
.header .mainMenu a.home:hover{
    background-position:10px -50px;
}
.header .mainMenu i{
    display:inline-block;
    height:40px;
    width:0px;
    border-left:1px solid #E3E3E3;
    border-right:1px solid #FFF;
    margin:0 10px;
    vertical-align:middle;
}
.header .menu .add{
    border-radius: 3px;
    color: #2c669c; /* 2A962F */
    font-size:14px;
    font-weight:bold;
    text-decoration:none;
    display: inline-block;
    line-height: 19px;
    margin: 7px 0 0 0;
    padding: 5px 15px 7px;
    text-shadow: 0 1px 0 #FFFFFF;
    text-transform:none;
    background: #fafafa;
    background: -moz-linear-gradient(top,  #fafafa 0%, #e4e4e4 100%);
    background: -webkit-linear-gradient(top,  #fafafa 0%,#e4e4e4 100%);
    background: linear-gradient(to bottom,  #fafafa 0%,#e4e4e4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#e4e4e4',GradientType=0 );
}
.header .menu .add:active{
    background: #fafafa;
    background: -moz-linear-gradient(top,  #e4e4e4 0%, #fafafa 100%);
    background: -webkit-linear-gradient(top,  #e4e4e4 0%,#fafafa 100%);
    background: linear-gradient(to bottom,  #e4e4e4 0%,#fafafa 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e4e4', endColorstr='#fafafa',GradientType=0 );
}
.header .menu .add img{
    margin:0 7px 0 0;
    vertical-align:middle;
}
.header .menu .edit img{
    margin:-1px 7px 0 0;
    vertical-align:middle;
}
.header .menu .edit{
    border-radius: 3px;
    color: #FFF;
    font-size:14px;
    text-decoration:none;
    display: inline-block;
    font-weight:normal;
    line-height: 19px;
    margin: 7px 5px 0 0;
    padding: 5px 15px 7px;
    text-transform:none;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #F60), color-stop(100%, #F00));
    background: -webkit-linear-gradient(#F60, #F00);
    background: -moz-linear-gradient(#F60, #F00);
    background: -o-linear-gradient(#F60, #F00);
    background: linear-gradient(#F60, #F00);
    background-color: #C40;
}
.header .menu .edit:active{
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #C40), color-stop(100%, #F40));
    background: -webkit-linear-gradient(#C40, #F40);
    background: -moz-linear-gradient(#C40, #F40);
    background: -o-linear-gradient(#C40, #F40);
    background: linear-gradient(#C40, #F40);
    background-color: #F40;
}

.header .siteName {
    height:85px;
    background:url(/images/fabric_0.jpg) 0 0 repeat-x;
}
.header .siteName a.logo{
    position:absolute;
    top:8px;
    left:0;
    display:block;
    width:212px;
    height:67px;
    background:url(/images/imoticom-logo.svg) 0 0 no-repeat;
}
.header div.info{
    position:absolute;
    margin-top:10px;
    font-size:20px;
    line-height:normal;
    color:#FFF;
}
.header .siteName div.info i{
    display:inline-block;
    height:28px;
    width:1px;
    margin:0 10px;
    background:#999;
    vertical-align:middle;
}
.header .loginBtn{
    display:inline-block;
    vertical-align: middle;
    width:0px;
    overflow:hidden;
    color:#FFF;
    font-size:14px;
    text-decoration:none;
    padding: 0 0 0 25px;
    margin-right:5px;
    background:url(/images/ico/login_usr_icon.png) 0 0 no-repeat;
    transition:width .2s;
}
.header .loginBtn:hover{
    width:40px;
}

.searchFrontPage{
    position:relative;
    z-index:1;
    min-height:350px;
    padding:40px 0 30px;
    background:url(/images/wellcome.jpg) no-repeat fixed center -120px / cover;
    border-bottom: 6px solid #49aeef;
    margin-bottom: 23px;
    width: 100%;
}
ul.frontPageFilters {
    list-style:none;
    display:inline-block;
    width:980px;
    margin:0;
}
ul.frontPageFilters li.filters{
    float:left;
    width:650px;
    margin-right:30px;
}
ul.frontPageFilters li.popularLI{
    float:left;
    width:300px;
}
ul.frontPageFilters .panel{
    background: rgba(255, 255, 255, 0.92) none repeat scroll 0 0;
    position: relative;
    width: 650px;
    min-height:328px;
    padding:101px 25px 20px;
    border-radius: 4px;
    box-shadow: 2px 2px 7px -5px #000000, -2px 2px 6px -4px #000000;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ul.frontPageFilters .panelName {
    position:absolute;
    z-index:1;
    top:20px;
    left:25px;
    width:150px;
    color:#2d659b;
    font-size:18px;
    line-height:20px;
    font-weight:bold;
    background:url(/images/ico/search.png) 0 0 no-repeat;
    padding-left:35px;
}
ul.frontPageFilters .panelType {
    position:absolute;
    z-index:2;
    top:20px;
    right:25px;
    width:380px;
    background: #f9f9f9;
    background: -moz-linear-gradient(top,  #f9f9f9 25%, #ffffff 76%);
    background: -webkit-linear-gradient(top,  #f9f9f9 25%,#ffffff 76%);
    background: linear-gradient(to bottom,  #f9f9f9 25%,#ffffff 76%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ffffff',GradientType=0 );
    border: 1px solid #CBCBCB;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 10px 13px 0 #F4F4F4 inset;
    height: 39px;
    padding: 1px 4px;
}

ul.frontPageFilters .panelType img.btnSlider{
    position:absolute;
    top:5px;
    width:186px;
    height:32px;
    transition: all .2s ease-in-out;
}

ul.frontPageFilters .panelType img.btnSlider.prodazhbi{
    left: 8px;
}

ul.frontPageFilters .panelType img.btnSlider.naemi{
    left: 196px;
}

ul.frontPageFilters .panelType ul{
    position:absolute;
    top:1px;
    left:6px;
}
ul.frontPageFilters .panelType li{
    width:186px;
    padding:5px 2px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ul.frontPageFilters .panelType a{
    float:left;
    display:block;
    width:186px;
    line-height:29px;
    text-align:center;
    text-decoration:none;
    color:#bebebe;
    font-size:16px;
    font-weight:bold;
}
ul.frontPageFilters .panelType a.current{
    color:#FFF;
}
ul.frontPageFilters .line{
    position:absolute;
    top:82px;
    left:0;
    right:0;
    height:0;
    border-top:1px solid #DDD;
}

ul.frontPageFilters ul.container2col {
    width:600px;
}
ul.frontPageFilters ul.container2col a{
    color:#004880 !important;
}
ul.frontPageFilters ul.container2col li{
    width:290px;
}
ul.frontPageFilters ul.container2col li.two{
    float:right;
}
.listFilters div.formWrapper,
.frontPageFilters div.formWrapper {
    float:right;
}
div.formWrapper {
    position:relative;
    width:100%;
    background-color: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    box-shadow: 0 1px 2px 2px #F4F4F4 inset;
    color: #666;
    font-size:14px;
    display: inline-block;
    line-height: 30px;
    padding:0 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
div.formWrapper.error{
border-color:#F00;
}
div.selectIcon {
    background:url(/images/ico/selectArrowDown.png) right center no-repeat #FFF;
}
div.formWrapper select{
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    height: 30px;
    color: #666;
    font-size:14px;
    opacity: 0;
    filter: alpha(opacity=0);
    ZOOM: 1;
    outline: medium none;
}
div.formWrapperSelect{
    padding:5px 0 0 5px;
}
div.formWrapperSelect select{
    position:relative;
    width:100%;
    height:auto;
    opacity: 1;
    filter: alpha(opacity=1);
    border:none;
}
ul.frontPageFilters ul.container2col span.icon{
    display:inline-block;
    width:30px;
    height:32px;
}
div.formWrapper input[type="text"],
div.formWrapper input[type="password"]{
    width:100%;
    height: 30px;
    color: #666;
    outline: medium none;
    border:0;
    font-size:14px;
    background:none;
}
div.formWrapper textarea{
    width:100%;
    color: #666;
    padding:3px 0;
    outline: medium none;
    border:0;
    font-size:14px;
    background:none;
}
ul.frontPageFilters ul.container2col div.title{
    font-weight:bold;
    font-size:15px;
    padding-left:35px;
    margin-bottom:5px;
}
ul.frontPageFilters ul.fromTo {
    padding-left:35px;
}
ul.frontPageFilters ul.fromTo li {
    width:50%;
}
ul.frontPageFilters ul.fromTo li.from {
    padding-right:8px;
}
ul.frontPageFilters ul.fromTo li.to {
    padding-left:8px;
}
ul.frontPageFilters ul.fromTo span{
    display:inline-block;
    width:23%;
    line-height:32px;
}
ul.frontPageFilters .formWrapper{
    width:256px;
}
ul.frontPageFilters ul.fromTo li .formWrapper{
    width:77%;
}
ul.frontPageFilters ul.container3col {
    width:600px;
}
ul.frontPageFilters ul.container3col li{
    width:192px;
    float:left;
}
ul.frontPageFilters ul.container3col li.one{
    position:relative;
}
ul.frontPageFilters ul.container3col li.two{
    margin:0 12px
}
ul.frontPageFilters ul.container3col li.three{
    text-align:right;
}
ul.frontPageFilters ul.container3col a{
    color:#004880;
    font-size:14px;
    font-weight:bold;
    cursor: pointer;
}
ul.frontPageFilters a.arrowDown{
    padding:0 15px 0 0;
    background:url(/images/ico/linkArrowDownGreen.png) right center no-repeat;
}
ul.frontPageFilters a.arrowRight{
    padding:0 0 0 15px;
    background:url(/images/ico/linkArrowRightGreen.png) left center no-repeat;
}
ul.frontPageFilters a.arrowLeft{
    padding:0 0 0 15px;
    background:url(/images/ico/linkArrowLeftGreen.png) left center no-repeat;
}

.mapFilter .map a{
    float:right;
    clear:right;
}

.scrollWidget{
    width: 300px;
    box-shadow: 2px 2px 7px -5px #000000, -2px 2px 6px -4px #000000;
    background: #EEEDEA;
    border-radius: 4px;
    overflow: hidden;
}
.scrollWidget div.title{
    font-size: 15px;
    border-radius: 4px 4px 0px 0px;
    background: #49aeef;
    background: -moz-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#49aeef),color-stop(100%,#0098d0));
    background: -webkit-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -o-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -ms-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,#49aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49aeef',endColorstr='#0098d0',GradientType=0 );
    color: white !important;
    font-size: 14px;
    font-weight:bold;
    margin: 0;
    padding: 14px 19px;
    height: 14px;
    text-shadow: 0 1px 0 #0d6e97;
    text-transform: uppercase;
    line-height: 15px;
}
.scrollWidget div.title img{
    vertical-align:middle;
    margin:0 10px 0 0;
}
.scrollWidget div.title a{
    float:right;
    color:#FFF;
    font-size:14px;
    font-weight:normal;
    text-decoration:underline;
    text-shadow:none;
    text-transform:none;
}
.scrollWidget div.title a:hover{
    text-decoration:none;
}
.scrollWidget a.UP,
.scrollWidget a.DOWN{
    display:block;
    background:url(/images/texture_bar.png) 0 0 no-repeat;
    height: 35px;
    text-align: center;
    cursor: pointer;
}
.scrollWidget a.UP img,
.scrollWidget a.DOWN img{
    margin-top:11px;
}
.scrollWidget div.locationsList {
    overflow:hidden;
    height:216px;
    position: relative;
}
.scrollWidget div.locationsList div.moving_locationsList{
    position: absolute;
    top:0;
    left:0;
    transition: all .4s ease-in-out;
    width: 100%;
}
.scrollWidget div.locationsList a{
    display: block;
    height: 18px;
    line-height:18px;
    padding: 8px 45px;
    color: #0098d0;
    font-size:14px;
    text-decoration: none;
    border-bottom: 1px solid #D8D7D4;
    border-top: 1px solid #FFFFFF;
    background: #f8f7f7;
    background: -moz-linear-gradient(top,  #f8f7f7 0%, #edece9 100%);
    background: -webkit-linear-gradient(top,  #f8f7f7 0%,#edece9 100%);
    background: linear-gradient(to bottom,  #f8f7f7 0%,#edece9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f7f7', endColorstr='#edece9',GradientType=0 );
}
.scrollWidget a.DOWN{
    margin-top:-1px;
}
.scrollWidget div.locationsList a i{
    float:right;
    font-style:normal;
    font-weight:normal;
    color:#808080;
}
.page {
    width:980px;
    margin:0 auto;
}
.agenciesWidget{
    position:relative;
    width:980px !important;
    margin-top:25px;
    overflow:visible;
}
.agenciesWidget .agenciesList{
    position:relative;
    display:inline-block;
    line-height:0;
    width:100%;
    height:306px;
    overflow:hidden;
    padding:5px 10px;
    background: #fefefe;
    background: -moz-linear-gradient(top,  #fefefe 0%, #eae9e6 100%);
    background: -webkit-linear-gradient(top,  #fefefe 0%,#eae9e6 100%);
    background: linear-gradient(to bottom,  #fefefe 0%,#eae9e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#eae9e6',GradientType=0 );
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.agenciesWidget .agenciesList .moving_agenciesList{
    position: absolute;
    top:0;
    left:0;
    transition: all .4s ease-in-out;
    width: 100%;
    margin:3px 10px;
}

.agenciesWidget .agenciesList a{
    float:left;
    display:block;
    height:40px;
    line-height:40px !important;
    width:110px;
    text-align:center;
    background:#FFF;
    border:1px solid #DDD;
    margin:3px;
    padding:1px;
    transition:opacity .2s;
}
.agenciesWidget .agenciesList a:hover{
    opacity:.5;
}
.agenciesWidget .agenciesList a img{
    max-width:110px;
    width:auto;
    height:auto;
    max-height:40px;
    vertical-align: middle;
}
.agenciesWidget a.DOWN{
    margin-top:-5px !important;
}

.agenciesWidget .agencyHint {
    display:none;
    position:absolute;
    z-index:1000;
    top:-98px;
    left:14px;
    background:#EEE;

    background: #eeeeee;
    background: -moz-linear-gradient(top,  #eeeeee 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top,  #eeeeee 0%,#ffffff 100%);
    background: linear-gradient(to bottom,  #eeeeee 0%,#ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff',GradientType=0 );

    border-radius:5px;
    box-shadow:0 1px 8px rgba(0,0,0,0.7);
    color:#444;
    width:250px;
    height:165px;
    white-space: nowrap;
    padding:10px 10px 5px;
}
.agenciesWidget .agencyHint i{
    display:block;
    width:19px;
    height:10px;
    background:url(/images/ico/agency-hint-arrow.png) 0 0 no-repeat;
    position:absolute;
    bottom:-10px;
    left:10px;
    z-index:1001;
}
.agenciesWidget .agencyHint img{
    border:1px solid #CCC;
    background:#FFF;
    padding:4px;
}
.agenciesWidget .agencyHint .name{
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
    margin-top:5px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.agenciesWidget .agencyHint .location{
    background:url(/images/ico/location-ag-hint.png) 0 0 no-repeat;
    padding-left:23px;
    margin-bottom:5px;
}
.agenciesWidget .agencyHint .phones{
    background:url(/images/ico/phone-ag-hint.png) 0 0 no-repeat;
    padding-left:23px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topOffersWidget {
    width:980px;
    position:relative;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7, -2px 2px 4px -2px #A7A7A7;
    background: #fefefe;
    background: -moz-linear-gradient(top,  #fefefe 0%, #eae9e6 100%);
    background: -webkit-linear-gradient(top,  #fefefe 0%,#eae9e6 100%);
    background: linear-gradient(to bottom,  #fefefe 0%,#eae9e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#eae9e6',GradientType=0 );
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #DFDCD7;
}
.topOffersWidget div.title{
    font-size:18px;
    font-weight:bold5;
    text-transform:uppercase;
    height:56px;
    line-height:56px;
    padding:0 55px;
}
.topOffersWidget div.title span{
    color:#ea822e;
}
.topOffersWidget div.type{
    position:absolute;
    top:-1px;
    left:-1px;
}
.topOffersWidget div.navigation{
    height:40px;
    line-height:40px;
    text-align:center;
}
.topOffersWidget div.navigation div{
    display:inline-block;
    line-height:0;
    margin:0 auto;
}
.topOffersWidget div.navigation a{
    display:inline-block;
    float:left;
    line-height:0;
    width:20px;
    height:20px;
    background:url(/images/ico/navBarDots.png) center 5px no-repeat;
    cursor: pointer;
}
.topOffersWidget div.navigation a.current{
    background-position: center -35px;
}
.topOffersWidget div.topOffers{
    height:275px;
}
.topOffersWidget div.topOffers a.PREV,
.topOffersWidget div.topOffers a.NEXT{
    float:left;
    display:block;
    height:284px;
    width:55px;
    background:url(/images/ico/TOP-navbar-arrow-PREV.png) center center no-repeat;
    cursor: pointer;
}
.topOffersWidget div.topOffers a.NEXT{
        background:url(/images/ico/TOP-navbar-arrow-NEXT.png) center center no-repeat;
}
.topOffersWidget div.topOffers div.offers{
    float:left;
    position: relative;
    height:284px;
    width:870px;
    padding-left:2px;
    overflow:hidden;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.topOffersWidget div.topOffers div.offers div.offers_moving {
    position: absolute;
    top:0;
    left:0;
    transition: all .4s ease-in-out;
}
.topOffersWidget div.offert{
    display: inline-block;
    position:relative;
    width:275px;
    height:275px;
    margin:0 17px 0 0;
    background:#FFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
}
.topOffersWidget div.offert .shadow{
    position:absolute;
    bottom:-10px;
    left:50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width:216px;
    height:10px;
    background:url(/images/topOffersShadow.png) 0 0 no-repeat;
}
.topOffersWidget div.noMargin{
    margin:0;
}
.topOffersWidget div.offert .photo{
    width:275px;
    height:180px;
    background:center center no-repeat;
    background-size:cover;
    border-radius: 4px 4px 0 0;
}
.topOffersWidget div.offert .photo a{
    display:block;
    height:180px;
    cursor: pointer;
}
.topOffersWidget div.offert .info .imot{
    text-align:center;
    color:#F80;
    font-weight:bold;
    font-size:15px;
    margin-top:10px;
}
.topOffersWidget div.offert .info .location{
    text-align:center;
    color:#717171;
    font-size:15px;
    padding:0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.topOffersWidget div.offert .info div.button{
    text-align:center;
}
.topOffersWidget div.offert .info a{
    display: inline-block;
    width:auto;
    height:26px;
    line-height:26px;
    margin-top:8px;
    background: #F60 none repeat scroll 0 0;
    border: 1px solid #F60;
    border-radius: 4px 4px 4px 4px;
    color: #FFF;
    font-size:15px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    padding:0 20px;
    text-transform:uppercase;
}
.topOffersWidget div.offert .info a:active{
    background: #F90 none repeat scroll 0 0;
    border: 1px solid #F90;
}

.footer{
    margin-top:15px;
}
.footer .lite{
    padding:35px 0 20px;
    background:url(/images/fabric_footer.jpg) repeat-x #d1d1d1;
    font-size:11px;
    line-height:14px !important;
}
.footer .lite div.title{
    color:#222;
    font-size:15px;
    font-weight:bold;
    text-transform:uppercase;
    margin-bottom:10px;
}
.footer .lite a{
    display:inline-block;
    color:#6a6a6a;
    text-decoration:none;
    transition:color .2s;
    padding:0 6px 0 0;
}
.footer .lite a:hover{
    color:#FFF;
}
.footer .dark{
    position:relative;
    background:#6b6b6b;
    height:100px;
}
.footer .dark a.footerLogo{
    position:absolute;
    top:28px;
    left:0;
    width:170px;
    height:55px;
    background:url(/images/footerLogo.svg) 0 0 no-repeat;
    background-size: contain;
}
.footer .dark .links{
    position:absolute;
    top:38px;
    left:220px;
    width:760px;
    color:#FFF;
    font-size:14px;
    line-height:22px;
    font-weight:bold;
}
.footer .dark .links a, .footer .dark .links span {
    color:#FFF;
    text-decoration:none !important;
}
.footer .dark .links i{
    display:inline-block;
    height:14px;
    width:0px;
    border-left:1px solid #4e4e4e;
    border-right:1px solid #868686;
    vertical-align:middle;
    margin:0 5px;
}
.footer .dark .links .copyright{
    float:right;
    font-weight:normal;
    color:#CCC;
}
.layout1 {
    width:980px;
    display:inline-block;
}
.layout1 div.main{
    float:left;
    width:800px;
    margin-right:20px;
}
.layout1 div.rightColumn{
    float:right;
    width:160px;
}
.list .item{
    position:relative;
    width:800px;
    height:192px;
    background:#FFF;
    margin:0 0 20px;
    padding:12px 12px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #A7A7A7;
    -webkit-box-shadow: 0px 0px 6px 0px #A7A7A7;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.list .item:hover{
     box-shadow: 0px 0px 12px 0px #666;
    -webkit-box-shadow: 0px 0px 15px 0px #666;
    transition:all .4s;
}
.list .item .title{
    display:block;
    width:100%;
    line-height:25px;
    margin-bottom:6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #179fda;
    font-size:22px;
}
.list .item .title .type{
    float:left;
}
.list .item .title .price{
    position:relative;
    float:right;
    color:#c00;
}
.list .item .photo{
    position:absolute;
    z-index:1;
    top:50px;
    left:12px;
    width:175px;
    height:131px;
    background-position:0 0;
    background-size:cover;
    background-repeat:no-repeat;
}

.list .item .photo img {
    width:175px;
    height:131px;
    object-fit: cover;
}

.list .item .info{
    padding-left:185px;
    font-size:14px;
}
.list .item .info .location{
    font-weight:bold;
    font-size:15px;
    margin-bottom:5px;
}
.list .item .info .phones{
    margin-top:5px;
    color:#000;
    font-size:15px;
    font-weight:bold;
}
.list .item a{
    position:absolute;
    z-index:2;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:2;
}
.list .item .info .controls{
    position:absolute;
    z-index:3;
    bottom:10px;
    left:198px;
    width:592px;
    height:26px;
    padding:0 !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.list .item .info .controls button{
    position:absolute;
    right:0;
    bottom:0;
    float:right;
    //width:210px;
    font-size:12px;
    line-height:26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list .item .info .controls .customCB span{
    color:#AAA;
}
.list .item .info .controls span.saved{
    color:#AAA;
    font-size:12px;
    font-style:italic;
    font-family:Arial, sans-serif;
}
.list .TOP .info .controls{
    position:absolute;
    z-index:3;
    bottom:10px;
    left:290px;
    width:500px;
    height:26px;
    padding:0 !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.addedToBel{
    background: #f00 !important; /*ff8800*/
    border-color:#f00 !important; /* ff8800 */
}
.addedToBel:active{
    background:#e00 !important;
    border-color:#e00 !important;
}
.addedToBel img{
    width:16px;
    height:14px;
}



/* CUSTOM CheckBox */
.customCB input[type=checkbox] {
    visibility:hidden;
}
.customCB {
    width: 240px;
    position: relative;
}
.customCB.error {
  border:#F00 1px solid;
}
.customCB label {
    cursor: pointer;
    position: absolute;
    top: 3px;
    left:0;
    width: 18px;
    height: 18px;
    padding:0 !important;
    border-radius: 2px;
    border:1px solid #CCC;
    white-space:nowrap;
    background: #FFF;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 2px #F4F4F4 inset;
}
.customCB label span{
    display:block;
    margin-top:-2px;
    padding-left:24px;
}
.customCB label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 2px;
    left: 2px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.customCB label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.5;
}
.customCB input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
/* END of CUSTOM CheckBox */




.list .TOP{
    padding:0;
    overflow:hidden;
    height:210px;
}
.list .TOP .title .type{
    width:70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list .TOP .title .price{
    width:30%;
    text-align:right;
}
.list .TOP .photo{
    top:0px;
    left:0px;
    width:280px;
    height:210px;
}

.list .TOP .photo img{
    width:280px;
    height:210px;
    object-fit: cover;
}

.list .TOP .adType{
    width:122px;
    height:24px;
    background:url(/images/ico/TOP.png) 0 0 no-repeat #ff7e00;
    position:absolute;
    z-index:1;
    top:0px;
    left:0px;
}
.list .TOP .title{
    color:#F70;
}
.list .TOP .info{
    padding-left:290px;
    padding-right:12px;
    padding-top:12px;
    padding-bottom:12px;
    font-size:14px;
}
.list .TOP .info .controls{
    padding-left:290px;
}
.list .VIP .adType{
    width:48px;
    height:47px;
    background:url(/images/ico/VIP.png) 0 0 no-repeat;
    position:absolute;
    z-index:1;
    top:0px;
    left:0px;
}
.list .VIP .title{
    color:#F60000;
    padding-left:30px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.pageNavigation {
    margin-top:12px;
    text-align:center;
}
.pageNavigation a{
    display: inline-block;
    margin: 0 2px;
    padding: 0 11px;
    height: 29px;
    line-height: 29px;
    color: #888;
    font-family: Arial;
    text-decoration:none;
    background: #f9f9f9;
    background: -moz-linear-gradient(top,#f9f9f9 0%,#f1f1f1 48%,#ececec 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#f9f9f9),color-stop(48%,#f1f1f1),color-stop(100%,#ececec));
    background: -webkit-linear-gradient(top,#f9f9f9 0%,#f1f1f1 48%,#ececec 100%);
    background: -o-linear-gradient(top,#f9f9f9 0%,#f1f1f1 48%,#ececec 100%);
    background: -ms-linear-gradient(top,#f9f9f9 0%,#f1f1f1 48%,#ececec 100%);
    background: linear-gradient(to bottom,#f9f9f9 0%,#f1f1f1 48%,#ececec 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9',endColorstr='#ececec',GradientType=0 );
    border: 1px solid #CDCDCD;
    border-radius: 3px 3px 3px 3px;
}
.pageNavigation a:hover{
    background:#EEE;
}
.pageNavigation a.big{
    width:60px;
    padding:0 8px;
}
.pageNavigation a.now{
    box-shadow: 0px 0px 15px gray inset;
    background: #d3d3d3 !important;
    color: white !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#B1B1B1',endColorstr='#d3d3d3',GradientType=0 );
}
.pageNavigation div{
    text-align:center;
    margin-bottom:15px;
}
.agencies{
    width:160px;
}
.agencies a{
    display:block;
    width:152px;
    margin-bottom:18px;
    text-align:center;
    background:#FFF;
    line-height:0;
    padding:4px;
}
.listFilters {
    display:inline-block;
    width: 980px;
    margin:0 0 10px;
    padding:0;
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #edece9 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#edece9 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#edece9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edece9',GradientType=0 );
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
    border-radius: 4px;
    color:#444;
    /*position:absolute;*/
    z-index:999999;
    /*top:190px;*/
}
.listFiltersTOP {
    position:fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
}
.listFiltersTOP .lfWrapper{
    width:980px;
    margin:0 auto;
}
.listFiltersTOP .filtersLogo {
    display:block;
}
.filtersLogo {
    display:none;
    position:absolute;
    /*top:15px;*/
    left:50%;
    margin-left:-630px;
}
.filtersLogo img{
    width:120px;
}
.listFilters .item{
    position:relative;
    float:left;
    width:163px;
}
.listFilters .item:hover{
    background:#edece9;
}
.listFilters .selectedItem{
    background:#ffffff;
}
.listFilters .head{
    position:relative;
    border-right:1px solid #FFF;
    border-left:1px solid #dadada;
    padding:10px 12px 11px;
    height:52px;
}
.listFilters .hFirst,
.listFilters .hFirst{
    width:164px;
}
.listFilters .hFirst {  border-radius: 4px 0 0 4px }
.listFilters .hLast {   border-radius: 0 4px 4px 0 }
.listFilters .hFirst .head{
    border-left:none;
}
.listFilters .hLast .head{
    border-right:none;
}
.listFilters .head a{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:url(/images/ico/listFiltersOpenArrow.png) right 56px no-repeat;
    cursor:pointer;
}
.listFilters .title{
    font-size:14px;
    font-weight:bold;
    margin-bottom:3px;
}
.listFilters .selectedOption{
    font-size:11px;
    line-height:14px;
    margin-top:2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}
.listFilters .menu{
    display:none;
    position:absolute;
    z-index:1000;
    top:68px;
    left:0;
    min-width:240px;
    padding:15px 20px;
    padding: 15px 11px;
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #edece9 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#edece9 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#edece9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edece9',GradientType=0 );
    border-radius: 4px;
    box-shadow: 2px 4px 4px -2px rgba(0,0,0,.3),-2px 4px 4px -2px rgba(0,0,0,.3);
    -webkit-box-shadow: 2px 4px 4px -2px rgba(0,0,0,.3),-2px 4px 4px -2px rgba(0,0,0,.3);
    -o-box-shadow: 1px 4px 4px -2px rgba(0,0,0,.3),-1px 4px 4px -2px rgba(0,0,0,.3);
    z-index: 50;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.listFilters .menuRight{
    left:auto;
    right:-1px;
}
.listFilters .menu .label{
    color:#555;
    font-weight:bold;
    margin-top:10px;
    margin-bottom:5px;
}
.listFilters .menu .label span{
    font-weight:normal;
}
.listFilters .menu .formWrapper{
    width:240px;
}
.listFilters .menu button{
    width:130px;
    margin-top:15px;
    float:right;
    padding:0 10px;
}
.listFilters ul.fromTo li {
    width:50%;
}
.listFilters ul.fromTo li.from {
    padding-right:8px;
}
.listFilters ul.fromTo li.to {
    padding-left:8px;
}
.listFilters ul.fromTo span{
    display:inline-block;
    width:23%;
    line-height:32px;
}
.listFilters ul.fromTo li .formWrapper{
    width:77%;
}
.listFilters .customCB{
    display:inline-block;
    margin-bottom:3px;
    clear:both;
}
.listFilters .customCB label span{
    color:#444;
}
.pageTitle {
    font-size:19px;
    font-weight:bold;
    margin:20px 0;
}
.pageTitle a.goto{
    float:right;
    font-size:13px;
    font-weight:normal;
}
.searchStatistics {
    position:relative;
    display:inline-block;
    width: 980px;
    margin:0 0 16px;
    /*margin:88px 0 16px;*/
    padding:7px 12px;
    background: #FFF;
    border-radius: 4px;
    color:#444;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.searchStatistics h1.info{
    font-size:12px;
    font-weight:normal;
    margin:8px 0;
    line-height:16px;
}
.searchStatistics div.info{
    margin:8px 0;
    line-height:16px;
}
.searchStatistics div.links{
    float:right;
    color:#AAA;
    cursor: pointer;
}
.searchStatistics div.links span {
    display: inline-block;
    text-decoration: underline;
    color: #004880;
}
.searchStatistics a.current{
    color:#ea822e !important;
    font-size:14px;
    font-weight:bold;
    text-decoration:none !important;
}
.searchStatAgency .logo{
    width:86px;
    position:absolute;
    top:10px;
    left:10px;
}
.searchStatAgency .logo a{
    display:block;
    padding:2px;
    background:#FFF;
    line-height:80px;
    text-align:center;
    border:1px solid #e6e6e6;
}
.searchStatAgency .logo a img{
    width:100%;
    max-width:80px;
    height:auto;
    vertical-align:middle;
}
.searchStatAgency div.address,
.searchStatAgency div.info,
.searchStatAgency div.stat{
    padding-left:95px;
}
.listPrint{
    display:inline-block;
    width:100%;
    height:32px;
    padding:5px 12px;
    background:#FFF;
    border-radius:4px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.listPrint a{
    float:right;
}
.listPrint .customCB{
    float:left;
}
.obiavaMainPanel {
    clear:both;
    padding:20px;
    margin-bottom:20px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.obiavaHeadLinks {
    position:relative;
    margin:20px 0 20px;
    color:#555;
    line-height:10px;
}
.obiavaHeadLinks div{
    float:right;
}
.obiavaHeadLinks a.add{
    position:absolute;
    top:-11px;
    right:0;
    display:inline-block;
    line-height:32px;
    width:160px;
    margin-left:20px;
    background:url(/images/ico/saveAdd.png) 15px center no-repeat #FFF;
    padding-left:40px;
    border:1px solid #CCC;
    border-radius:4px;
    color:#777;
    font-size:14px;
    text-decoration:none;
}
.obiavaHeadLinks a.add:hover{
    background-color:#F3F3F3;
}
.obiavaHeadLinks a.added{
    background:url(/images/ico/saveAddWhite.png) 15px center no-repeat #f00; /* f67600 */
    color:#FFF;
    border:1px solid #f00; /* e56e00 */
}
.obiavaHeadLinks a.added:hover{
    background-color:#f00; /* f66800 */
}
a.addFB{
    display:inline-block;
    line-height:32px;
    width:160px;
    background:url(/images/ico/fb-share.png) 15px center no-repeat #FFF;
    padding-left:40px;
    border:1px solid #CCC;
    border-radius:4px;
    color:#777;
    font-size:14px;
    text-decoration:none;
    cursor: pointer;
}
a.addFB:hover{
    background-color:#F5F5F5;
}
a.addedFB{
    background:url(/images/ico/fb-saved.png) 15px center no-repeat #5890ff;
    color:#FFF;
    border:1px solid #436ec2;
}
a.addedFB:hover{
    background-color:#436ec2;
}
.obiavaHeadLinks a.addFB{
    position:absolute;
    top:-11px;
    right:210px;
}
.obiavaMainPanel div.advertTitle{
    position:relative;
    margin-bottom:20px;
}
.obiavaMainPanel div.advertTitle h1.name{
    color:#179fda;
    font-size:22px;
    font-weight:normal;
    margin:0px;
}
.obiavaMainPanel div.advertTitle h1.name span{
    display:block;
    margin-top:10px;
    color:#333;
    font-size:15px;
    font-weight:bold;
}
.obiavaMainPanel div.advertTitle .price{
    position:absolute;
    top:0;
    right:0;
    color:#c00;
    font-size:22px;
    font-weight:bold;
    text-align:right;
}
.obiavaMainPanel div.advertTitle .price span{
    display:inline-block;
    text-align:left;
    width:100%;
    font-weight:normal;
    font-size:14px;
    color:#c00;
}
.obiavaMainPanel div.advertTitle .price a.priceInfoIcon{
    display:inline-block;
    margin-left:7px;
    width:16px;
    height:16px;
    background:url(/images/ico/priceInfoIcon.png) 0 0 no-repeat;
    cursor:default;
}
.obiavaMainPanel ul.layout li.main{
    width:640px;
    margin-right:20px;
}
.obiavaMainPanel ul.layout li.right{
    width:280px;
}
.obiavaMainPanel ul.layout .photoGallery{
    margin-bottom:20px;
    border:1px solid #d4d2d3;
    overflow:hidden;
}
.obiavaMainPanel ul.layout li.main .info{
    font-size:15px;
    white-space: pre-line;
}
.obiavaMainPanel ul.layout li.main .info div{
    font-size:18px;
    margin-bottom:15px;
}
.obiavaMainPanel .agencyWidget {
    margin-bottom: 20px;
    background: rgb(251,250,250);
    background: -moz-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(251,250,250,1)),color-stop(100%,rgba(237,236,233,1)));
    background: -webkit-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -o-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -ms-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: linear-gradient(to bottom,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfafa',endColorstr='#edece9',GradientType=0 );
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.obiavaMainPanel .agencyWidget .head{
    padding: 8px;
    line-height: 16px;
    border-radius: 5px 5px 0 0;
    background: #49aeef;
    background: -moz-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#49aeef),color-stop(100%,#0098d0));
    background: -webkit-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -o-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -ms-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,#49aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49aeef',endColorstr='#0098d0',GradientType=0 );
    color: #FFF;
    font-size: 14px;
    line-height: 17px;
    font-weight:bold;
    text-transform: uppercase;
}
.obiavaMainPanel .agencyWidget .head img{
    width:24px;
    height:20px;
    vertical-align:-5px;
    margin-right:7px;
}
.obiavaMainPanel .agencyWidget .main{
    padding:10px 10px 5px;
    border-top:1px solid #ffffff;
    border-bottom:1px solid #d2d2d2;
    font-size:12px;
    line-height:normal;
    overflow: hidden;
}
.obiavaMainPanel .agencyWidget #broker{
    overflow:hidden;
    padding-top:5px;
    padding-bottom:5px;
}
.obiavaMainPanel .agencyWidget .buttons{
    padding:10px 10px;
    border-top:1px solid #fff;
}
.obiavaMainPanel .agencyWidget .buttons button{
    border-radius:0;
}
.obiavaMainPanel .agencyWidget .buttons .mailButton{
    /*width:49%;*/
    /*margin-right:1%;*/
    font-size:12px;
    line-height:28px;
}
.obiavaMainPanel .agencyWidget .buttons .mailButton img{
    vertical-align:-1px;
    margin-left:5px;
}
.obiavaMainPanel .agencyWidget .buttons .phonesButton{
    width:49%;
    margin-left:1%;
    font-size:12px;
    line-height:28px;
    background: #00a651;
    border-color:#00a651;
}
.obiavaMainPanel .agencyWidget .buttons .phonesButton:active{
    background: #008f46;
    border-color:#008f46;
}
.obiavaMainPanel .agencyWidget .buttons .phonesButton img{
    vertical-align:-1px;
    margin-left:5px;
}
.obiavaMainPanel .agencyWidget .main li.logo{
    width:80px;
}
.obiavaMainPanel .agencyWidget .main li.info{
    width:160px;
    padding-left:10px;
}
.obiavaMainPanel .agencyWidget .main li.logo a{
    display:block;
    padding:2px;
    background:#FFF;
    line-height:74px;
    text-align:center;
    border:1px solid #e6e6e6;
}
.obiavaMainPanel .agencyWidget .main li.logo a img{
    width:100%;
    max-width:74px;
    height:auto;
    vertical-align:middle;
}
.obiavaMainPanel .agencyWidget .main .agency{
    display:block;
    margin-bottom:10px;
    font-weight:bold;
    color:#333;
    font-size:13px;
    text-decoration:none;
}
.obiavaMainPanel .agencyWidget .main .agency span{
    font-weight:normal;
}
.obiavaMainPanel .agencyWidget .main .broker{
    background:url(/images/ico/broker-ico.png) 0 0 no-repeat;
    padding-left:20px;
}
.obiavaMainPanel .agencyWidget .main .agencyButton{
    width:100%;
    margin-top:15px;
    border: 1px solid #AFAFAF;
    border-radius: 3px 3px 3px 3px;
    -webkit-box-shadow: 0 1px 0 white;
    -o-box-shadow: 0 1px 0 white;
    box-shadow: 0 1px 0 white;
    color: black;
    font-size: 13px;
    text-decoration:none;
    text-align:center;
    padding: 3px 8px;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(255,255,255,1)),color-stop(100%,rgba(237,236,233,1)));
    background: -webkit-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: -o-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: -ms-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: linear-gradient(to bottom,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff',endColorstr='#edece9',GradientType=0 );
}
.obiavaMainPanel .adLinks{
    clear:both;
    border-top:1px solid #d2d2d2;
    margin-top:12px;
    padding-top:16px;
}
.obiavaMainPanel .adLinks a , .obiavaMainPanel .adLinks span{
    display:inline-block;
    font-size:16px;
    line-height:21px;
    text-decoration:none;
}
.obiavaMainPanel .adLinks .add{
    background:url(/images/ico/heart-ico.png) 0 0 no-repeat;
    padding-left:33px;
}
.obiavaMainPanel .adLinks .added{
    background:url(/images/ico/heart-ico-orange.png) 0 0 no-repeat;
    color:#f00;  /* f66800 */
}
.obiavaMainPanel .adLinks .send{
    background:url(/images/ico/email-ico.png) 0 3px no-repeat;
    padding-left:46px;
}
.obiavaMainPanel .adLinks .print{
    background:url(/images/ico/print-ico.png) 0 0 no-repeat;
    padding-left:31px;
}
.obiavaMainPanel .adLinks div{
    float:right;
}
.obiavaMainPanel .adLinks div a{
    margin-left:30px;
}
.obiavaMap {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
    overflow: hidden;
}
.obiavaMap .map {
    padding:15px 20px 20px;
    line-height:0;
}
.obiavaMap .head {
    display:inline-block;
    width:100%;
    background-color: #F6F5F3;
    border-bottom: 1px solid #D3D3D3;
    padding: 8px 20px;
    line-height:normal;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.obiavaMap .head .title{
    float:left;
    margin:0px;
}
.obiavaMap .head .title strong{
    color: #00a651;
    font-size: 18px;
    padding: 5px 15px 0 0;
}
.obiavaMap .head .location{
    float:right;
    padding-top:3px;
    width:660px;
    text-align:right;
}
.obiavaMap .mapButton {
    padding:15px 20px 0;
}
.obiavaMap .mapButton div{
    margin-top:14px;
    padding-bottom:14px;
    font-size:13px;
    color:#22a2de;
    text-align:center;
    border-bottom:1px solid #DDD;
}


.mainInfo .title{
    font-size: 18px;
    margin-bottom:12px;
}
.mainInfo .params div{
    display:inline-block;
    width:100%;
    line-height:normal;
    margin-bottom:0px;
}
.mainInfo .params i{
    float:left;
    display:inline-block;
    width:100px;
    font-style:normal;
}
.mainInfo .params span{
    float:left;
    display:inline-block;
    width:180px;
    font-style:normal;
    font-weight:bold;
}
.social {
    position: fixed;
    z-index: 10001;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -60px;
    padding: 7px 9px 7px 13px;
    border-radius: 15px 0px 0px 15px;
    box-shadow: 0px 0px 9px -3px black;
    line-height:0;
    background: rgb(251,250,250);
    background: -moz-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(251,250,250,1)),color-stop(100%,rgba(237,236,233,1)));
    background: -webkit-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -o-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -ms-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: linear-gradient(to bottom,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfafa',endColorstr='#edece9',GradientType=0 );
}
.social a{
    display:block;
    width:36px;
    height:36px;
    background-image:url(/images/ico/social.png);
    background-repeat:no-repeat;
    margin:8px 0;
}
.social a:hover     {   opacity:.5                  }
.social a.FB        {   background-position:0 0     }
.social a.TW        {   background-position:0 -36px }
.social a.GP        {   background-position:0 -72px }
.social a.IN        {   background-position:0 -108px}
.social a.close{
    position:absolute;
    top:-4px;
    right:4px;
    display:block;
    width:7px;
    height:7px;
    background-image:url(/images/ico/closeSocialLinks.png);
    background-repeat:no-repeat;
}
.social a.close:hover{
    opacity:1;
    background-position: 0 -10px;
}
.priceBaloon {
    display:none;
    position:absolute;
    z-index:99999999;
    top:-90px;
    right:0;
    min-width:100px;
    background-color:rgba(0,0,0,0.8);
    padding:10px;
    color:#FFF;
    font-weight:normal;
    font-size:13px;
    text-align:left;
    white-space:nowrap;
    border:1px solid #FFF;
    box-shadow:0 0 5px #333;
    border-radius:3px;
}
.priceBaloon div{
    position:relative;
}
.priceBaloon span{
    display:inline !important;
    width:auto !important;
    color:#AAA !important;
    font-size:12px !important;
}
.priceBaloon img{
    position:absolute;
    right:0;
    bottom:-21px;
}
.fixedScreen{
    display:none5;
    position:fixed;
    z-index:9999999;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background: rgba(0,0,0,0.3);
}
.hMess {
    border-bottom:1px solid #CCC;
    padding-bottom:15px;
    margin-bottom:15px;
    text-align:right;
    color:#666;
    font-size:14px;
}
.hMess button{
    line-height:26px;
    height:26px;
    margin-left:10px;
}
.emailForms{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width:430px;
    min-height:400px;
    overflow:hidden;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
    border-radius: 6px 6px 4px 4px;
    background: #fefefe;
    background: -moz-linear-gradient(top,#fefefe 0%,#fdfdfd 17%,#f6f5f4 44%,#efeeeb 73%,#eae9e6 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#fefefe),color-stop(17%,#fdfdfd),color-stop(44%,#f6f5f4),color-stop(73%,#efeeeb),color-stop(100%,#eae9e6));
    background: -webkit-linear-gradient(top,#fefefe 0%,#fdfdfd 17%,#f6f5f4 44%,#efeeeb 73%,#eae9e6 100%);
    background: -o-linear-gradient(top,#fefefe 0%,#fdfdfd 17%,#f6f5f4 44%,#efeeeb 73%,#eae9e6 100%);
    background: -ms-linear-gradient(top,#fefefe 0%,#fdfdfd 17%,#f6f5f4 44%,#efeeeb 73%,#eae9e6 100%);
    background: linear-gradient(to bottom,#fefefe 0%,#fdfdfd 17%,#f6f5f4 44%,#efeeeb 73%,#eae9e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe',endColorstr='#eae9e6',GradientType=0 );
}
.emailForms .head{
    position:relative;
    line-height: 28px;
    padding: 5px 15px 6px;
    background: #49aeef;
    background: -moz-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#49aeef),color-stop(100%,#0098d0));
    background: -webkit-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -o-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -ms-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,#49aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49aeef',endColorstr='#0098d0',GradientType=0 );
    color: #FFFFFF;
    font-size: 18px;
    font-weight:bold;
    text-transform:uppercase;
}
.emailForms .head a.close{
    position:absolute;
    right:15px;
    top:9px;
    width:22px;
    height:22px;
    background:url(/images/ico/close-button-modal.png) 0 0 no-repeat;
}
.emailForms .head a.close:hover{
    opacity:.6;
}
.emailForms .forms{
    padding: 15px 15px 20px;
}
.emailForms .forms table{
    width:400px;
    border:none;
    border-collapse:collapse;
}
.emailForms .forms table td{
    padding:3px 0;
    vertical-align:top;
}
.emailForms .forms table .col1{
    width:120px;
    font-size:15px;
    padding-top:8px;
    text-align:right;
    font-weight:bold;
    color:#898989;
}
.emailForms .forms table .col1 span{
    color:#F30;
}
.emailForms .forms table .col2{
    width:10px;
}
.emailForms .forms table .col3{
    width:270px;
}
.emailForms .forms input[type="text"]{
    width:100%;
}
.emailForms .forms textarea{
    width:100%;
}
.emailForms .forms label{
    display:inline-block;
    padding-right:10px;
    font-size:14px;
}
.emailForms .forms input[type="radio"]{
    margin:0;
    padding:0;
    vertical-align:middle;
    margin-right:5px;
}
.emailForms .formWrapper{
    float:none;
    width:90%;
}
.emailForms .code{
    width:auto;
    line-height:0;
    padding:0;
    margin-left:5px;
    overflow:hidden;
    vertical-align:top;
}
.emailForms .informMe {
    margin-top:10px;
}
.emailForms .informMe input[type="checkbox"]{
    margin:0 5px 0 0;
    vertical-align:middle;
}
.emailForms .sendButton{
    margin:20px auto 0;
    text-align:center;
}
.emailForms button{
    width:auto;
}
.emailForms button img{
    width:auto;
    height:auto;
    vertical-align:0px;
    margin:0 0 0 2px;
}
.emailForms .statusError{
    background: #f00 none repeat scroll 0 0;
    border: 1px solid #f00;
    color: #fff;
    display: none;
    font-weight: 700;
    height: 23px;
    line-height: 23px;
    margin-top: 8px;
    overflow: hidden;
    padding: 0 7px;
    text-align: center;
}

.brokerWidget {
    display:inline-block;
    width:100%;
    margin-bottom: 20px;
    background: rgb(251,250,250);
    background: -moz-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(251,250,250,1)),color-stop(100%,rgba(237,236,233,1)));
    background: -webkit-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -o-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: -ms-linear-gradient(top,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    background: linear-gradient(to bottom,rgba(251,250,250,1) 0%,rgba(237,236,233,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfafa',endColorstr='#edece9',GradientType=0 );
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.brokerWidget .head{
    padding: 8px;
    line-height: 16px;
    border-radius: 5px 5px 0 0;
    background: #49aeef;
    background: -moz-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#49aeef),color-stop(100%,#0098d0));
    background: -webkit-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -o-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -ms-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,#49aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49aeef',endColorstr='#0098d0',GradientType=0 );
    color: #FFF;
    font-size: 14px;
    line-height: 17px;
    font-weight:bold;
    text-transform: uppercase;
}
.brokerWidget .head img{
    width:24px;
    height:20px;
    vertical-align:-5px;
    margin-right:7px;
}
.brokerWidget .main{
    width:100% !important;
    padding:10px !important;
    border-top:1px solid #ffffff;
    border-bottom:1px solid #d2d2d2;
    font-size:12px;
    line-height:normal;
    background:#FFF;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.brokerWidget div{
    overflow:hidden;
}
.brokerWidget #broker{
    overflow:hidden;
    padding-top:0;
    padding-bottom:0;
}
.brokerWidget button{
    border-radius:0;
}
.brokerWidget .mailButton{
    margin-right:0;
    font-size:12px;
    line-height:28px;
}
.brokerWidget .mailButton img{
    vertical-align:-1px;
    margin-left:5px;
}
.brokerWidget .main li.logo{
    width:100%;
}
.brokerWidget .main li.info{
    width:100%;
}
.brokerWidget .main li.logo a{
    padding:2px;
    background:#FFF;
    line-height:74px;
}
.brokerWidget .main li.logo a img{
    width:100%;
    max-width:74px;
    height:auto;
    vertical-align:middle;
}
.brokerWidget .main .agency{
    display:block;
    margin-bottom:10px;
    font-weight:bold;
    color:#333;
    font-size:13px;
    text-decoration:none;
}
.brokerWidget .main .agency span{
    font-weight:normal;
}
.brokerWidget .main .broker{
    background:url(/images/ico/broker-ico.png) 0 0 no-repeat;
    padding-left:20px;
}
.advancedSearch {
    width:940px;
    padding: 20px;
    margin-bottom: 20px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.advancedSearch table{
    width:940px;
    border-collapse:collapse;
    border:none;
    margin:0;
    padding:0;
}
.advancedSearch td{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin:0;
    padding:0;
    vertical-align:top;
}
.advancedSearch .tab5 td{
    width:188px;
}
.advancedSearch .tab6 td{
    width:156px;
}
.advancedSearch .tab55 td{
    width:235px;
}
.advancedSearch .tab55 td.half{
    width:117px;
}
.advancedSearch .tab55 td .formWrapper{
    width:102px;
}
.advancedSearch .tab55 td span.mid{
    display:inline-block;
    width:10px;
}
.advancedSearch .tab2 td.button{
    width:460px;
}
.advancedSearch .tab2 td.sort{
    width:480px;
    padding-right:220px;
}
.advancedSearch table.mapTab td.map{
    width:460px;
    padding-right:20px;
}

.advancedSearch table.mapTab td.mapFilters{
    width:400px;
}
.advancedSearch table.mapTab td.mapFilters table{
    width:400px;
}
.advancedSearch table.mapTab td.loc1{
    width:185px;
}
.advancedSearch table.mapTab td.btns{
    width:30px;
    vertical-align:top;
}
.advancedSearch table.mapTab td.btns a.in{
    margin-top:75px;
}
.advancedSearch table.mapTab td.loc2{
    width:185px;
}
.advancedSearch .mH{
    height:60px;
    margin-bottom:10px;
}
.advancedSearch .title{
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
}
.advancedSearch a.in,
.advancedSearch a.out,
.results_btn a.in,
.results_btn a.out{
    display:block;
    width:24px;
    height:24px;
    line-height:24px;
    margin:10px auto;
    border-radius:5px;
    background:#777;
    color:#FFF;
    text-align:center;
    text-decoration:none;
    font-size:19px;
}
.advancedSearch a.in:hover,
.advancedSearch a.out:hover,
.results_btn a.in:hover,
.results_btn a.out:hover{
    background:#666;
}
.advancedSearch .line{
    position:relative;
    height:1px;
    margin:20px 0 7px;
    background:#DDD;
}
.advancedSearch .customCB{
    width:100%;
    margin:0 0 2px;
}
.advancedSearch .customCB span{
    color:#333;
    font-size:12px;
}


.advancedSearch .moreOptions1col {
    margin-top:15px;
}
.advancedSearch .moreOptions1col.error {
    border:#F00 1px solid;
}
.advancedSearch .moreOptions1col .formWrapper{
    width:87px;
}
.advancedSearch .moreOptions1col span.mid{
    display:inline-block;
    width:10px;
    text-align:center;
}
.advancedSearch .moreOptions1col table{
    width:100%;
}
.advancedSearch .moreOptions1col table td{
    width:33.3%;
}
.advancedSearch .moreOptions1col input[type="checkbox"]{
    margin:0 3px 0 0;
    vertical-align:-3px;
}
.addPage {
    width:940px;
    padding: 20px;
    margin-bottom: 20px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.addPage table{
    width:940px;
    border-collapse:collapse;
    border:none;
    margin:0 auto;
    padding:0;
}
.addPage td{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin:0;
    padding:5px 0;
    vertical-align:top;
}
.addPage td.lab{
    width:300px;
    padding-right:20px;
    padding-top:11px;
    text-align:right;
    font-weight:bold;
    font-size:14px;
}
.addPage td.lab i{
    font-style:normal;
    color:#F00;
}
.addPage .formWrapper {
    width:410px;
}
.addPage .pageTitle{
    margin-top:0px;
}
.fasha{
    width:100%;
    background: #49aeef;
    background: -moz-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#49aeef),color-stop(100%,#0098d0));
    background: -webkit-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -o-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: -ms-linear-gradient(top,#49aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,#49aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49aeef',endColorstr='#0098d0',GradientType=0 );
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 7px 0;
    color: #ffffff;
    font-weight:bold;
    text-indent: 9px;
    text-transform: uppercase;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.addPage td span.mid{
    display:inline-block;
    width:10px;
}
.addPage .tab2col{
    width:408px;
    margin:0;
}
.addPage .tab2col td{
    width:136px;
}
.addPage .tab2col .customCB{
    width:100%;
}
.addPage button.addPhotos {
    width: auto;
    padding: 0 15px 0 10px !important;
    line-height:32px;
}
.addPage button.add {
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    border: 0 none;
    padding: 4px 16px;
    text-transform: uppercase;
    background: rgb(133,133,133);
    background: -moz-linear-gradient(top,rgba(133,133,133,1) 0%,rgba(82,82,82,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(133,133,133,1)),color-stop(100%,rgba(82,82,82,1)));
    background: -webkit-linear-gradient(top,rgba(133,133,133,1) 0%,rgba(82,82,82,1) 100%);
    background: -o-linear-gradient(top,rgba(133,133,133,1) 0%,rgba(82,82,82,1) 100%);
    background: -ms-linear-gradient(top,rgba(133,133,133,1) 0%,rgba(82,82,82,1) 100%);
    background: linear-gradient(to bottom,rgba(133,133,133,1) 0%,rgba(82,82,82,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#858585',endColorstr='#525252',GradientType=0 );
    color: #ffffff;
    width:auto;
    height: 42px;
    line-height: 42px;
    padding: 0 20px 0 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.addPage button.add:hover {
    opacity:.9;
}
.addPage span.addPhotosNote{
    display:inline-block;
    padding:2px 10px 2px 32px;
    background: url(/images/ico/alert.png) 7px center no-repeat #f9edbe;
    border:1px solid #ffeeaa;
    line-height:28px;
    border-radius:4px;
    color:#555;
    font-size:12px;
    font-weight:bold;
}
.belNote {
    margin-top:-25px;
    margin-bottom:20px;
    padding:10px 10px 5px;
    background:#f9edbe;
    border:1px solid #f9edbe;
    border-radius: 0 0 5px 5px;
    border-bottom:1px solid #e5daaf;
}
.belNote input[type="text"]{
    width:618px;
    margin-right:10px;
    background:none;
    border:none;
    height:24px;
}
.belNote button{
    width:150px;
    line-height:24px;
    font-size:11px;
    padding:0 10px;
}
.agenciiSelections {
    text-align:center;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
    border-radius:5px;
    overflow:hidden;
    margin-bottom:20px;
    background:#FFF;
}
.agenciiSelections .fasha{
    box-shadow:none;
    -webkit-box-shadow:none;
    -o-box-shadow:none;
    border-radius:0;
    margin-top:0;
}
.agenciiSelections .options{
    padding:0 5px 13px;
    font-size:14px;
    line-height:20px;
}
.agenciiSelections .options a{
    display:inline-block;
    padding:0 2px;
}
.agenciiSelections .options a:hover{
    color:#ea822e;
}
.agenciiSelections .options a.current{
    font-weight:bold;
    text-decoration:none;
    color:#ea822e;
    font-size:16px;
}
.list .agItem{
    position:relative;
    display:inline-block;
    width:800px;
    background:#FFF;
    margin:0 0 10px;
    padding:12px 12px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #A7A7A7;
    -webkit-box-shadow: 0px 0px 6px 0px #A7A7A7;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.list .agItem:hover{
     box-shadow: 0px 0px 12px 0px #666;
    -webkit-box-shadow: 0px 0px 15px 0px #666;
    transition:all .4s;
}
.list .agItem .info{
    width:620px;
    float:right;
}
.list .agItem .logo{
    width:150px;
    float:left;
    text-align:center;
    line-height:0;

}
.list .agItem .logo img{
    width:auto;
    height:auto;
    max-width:135px;
    max-height:50px;
}
.list .agItem .info .name{
    font-size:16px;
    color:#22a0da;
    font-weight:bold;
    margin-bottom:10px;
}
.list .agItem .info .adress{
    font-size:14px;
    margin-bottom:2px;
}
.list .agItem .info .adress{
    font-size:14px;
}
.list .agItem a{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}
.agHeadList {
    display:inline-block;
    width:100%;
    margin-bottom:15px;
}
.agHeadList strong{
    font-size:15px;
    margin-top:6px;
    display:inline-block;
}
.agHeadList .sorting{
    float:right;
    width:350px;
    text-align:right;
    font-size:14px;
}
.agHeadList .sorting .formWrapper{
    display:inline-block;
    width:150px;
    text-align:left;
    margin-left:5px;
}
.login {
    width:940px;
    padding: 20px;
    margin-top:0px;
    margin-bottom: 20px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
}
.login table{
    width:400px;
    border-collapse:collapse;
    border:none;
    margin:0;
    padding:0;
}
.login td{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin:0;
    padding:0 0 10px;
    border: none;
}
.login td.lab{
    width:120px;
    font-weight:bold;
    font-size:14px;
}
.login .pageTitle {
    margin-top:0;
}
.searchStatisticsGradove {
    line-height:17px;
}
.searchStatisticsGradove a {
    margin-right:10px;
}
.searchStatisticsGradove a:hover {
    color:#ea822e;
}
.searchStatisticsGradove a.current{
    color:#ea822e;
    font-size:14px;
    font-weight:bold;
}
#cookies {
    display:none;
    background:#FEFFDA;
    overflow:hidden;
    line-height:44px;
    font-size:14px;
}
#cookies div{
    width:960px;
    margin:0 auto;
    background:url(/images/ico/info-ico.png) 0 center no-repeat;
    padding-left:30px;
}
#cookies a, #cookies span {
    color:#004880;
    font-weight:bold;
    cursor: pointer;
}
.hint {
    display:none;
    position:absolute;
    z-index:1000;
    bottom:-39px;
    left:0;
    background:rgba(0,0,0,0.7);
    border:2px solid #FFF;
    border-radius:5px;
    box-shadow:0 2px 5px rgba(0,0,0,0.7);
    color:#FFF;
    width:auto;
    white-space: nowrap;
    padding:0px 12px;
}
.hint i{
    display:block;
    width:14px;
    height:7px;
    background:url(/images/ico/hint-arrow.png) 0 0 no-repeat;
    position:absolute;
    top:-7px;
    z-index:1001;
}
div.formWrapper:hover .hint{
    display:block;
}

.errorMessage {
    display:none;
    background-color:#F00;
    padding:20px 20px;
    color:#FFF;
    font-size:16px;
    text-align:center;
    border-radius:4px;
}
.errorMessage img{
    margin-bottom:5px;
}
.formError {
    border:1px solid #F00 !important;
    background-color:#f3f3ec !important;
}
.okMessage {
    display:none;
    background-color:#00a651;
    padding:20px 20px;
    margin-bottom:20px;
    color:#FFF;
    font-size:16px;
    text-align:center;
    border-radius:4px;
}
.okMessage img{
    margin-bottom:5px;
}
.editBtn {
    margin-bottom:20px;
    line-height:60px !important;
    height:60px !important;
}
.editBtn img{
    margin-right:10px;
    width:auto;
    height:auto;
}
.topBtn {
    background:#ff7e00;
    border:1px solid #ff7e00;
    margin-bottom:20px;
    line-height:60px !important;
    height:60px !important;
}
.topBtn:active {
    background:#ff9900;
    border:1px solid #ff9900;
}
.topBtn span,
.vipBtn span{
    font-weight:normal;
}
.vipBtn {
    background:#f00;
    border:1px solid #f00;
    margin-bottom:20px;
    line-height:60px !important;
    height:60px !important;
}
.vipBtn:active {
    background:#e60000;
    border:1px solid #e60000;
}
.addPhotosList {
    display:none;
    margin-top:10px;
}
.addPhotosList div {
    position:relative;
    width:145px;
    height:109px;
    background:#EEE;
    text-align:center;
    line-height:0;
    display:table-cell;
    vertical-align:middle;
    cursor:move;
    float:left;
    margin-right:12px;
    margin-bottom:12px;
}
.addPhotosList div img{
    max-width:145px;
    max-height:109px;
}
.addPhotosList div a.close{
    position:absolute;
    top:-8px;
    right:-8px;
    display:block;
    width:22px;
    height:22px;
    background:url(/images/ico/delete-photo.png) 0 0 no-repeat;
}
.addPhotosList div a.rotate{
    position:absolute;
    top:-8px;
    right:13px;
    display:block;
    width:22px;
    height:22px;
    background:url(/images/ico/rotate-photo.png) 0 0 no-repeat;
}
.addPhotosList div a.close:hover,
.addPhotosList div a.rotate:hover{
    background-position:0 -32px;
}

.lastSearches {
    display:none;
    position:absolute;
    z-index:1000;
    bottom:-47px;
    left:0;
    background:#EEE;

    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #eeeeee 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#eeeeee 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#eeeeee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 );

    /*border:2px solid #FFF;*/
    border-radius:5px;
    box-shadow:0 1px 8px rgba(0,0,0,0.7);
    color:#FFF;
    width:auto;
    white-space: nowrap;
    padding:3px 3px;
}
.lastSearches i{
    display:block;
    width:19px;
    height:10px;
    background:url(/images/ico/lastsearch-arrow.png) 0 0 no-repeat;
    position:absolute;
    top:-10px;
    left:10px;
    z-index:1001;
}
.lastSearches a{
    display:block;
    color:#888 !important;
    font-weight:normal !important;
    text-decoration:none;
    line-height:24px;
    padding:0 10px;
}
.lastSearches a:hover{
    color:#666 !important;
    background:#E9E9E9;
}

.cityHints {
    display:none;
    position:absolute;
    z-index:500;
    left:0;
    margin-top:10px;
    width:253px;
    height:400px;
    background:#FFF;
    border:1px solid #CCC;
    border-radius:5px;
    box-shadow:0 0 15px rgba(0,0,0,0.5);
    overflow-y:scroll;
}
ul.frontPageFilters ul.container2col .cityHints a{
    display:block;
    line-height:18px;
    border-bottom:1px solid #DDD;
    border-radius:5px;
    color:#555 !important;
    text-decoration:none;
    padding:6px 10px 8px;
}
ul.frontPageFilters ul.container2col .cityHints a:hover{
    color:#FFF !important;
    background: #48aeef;
    background: -moz-linear-gradient(top,  #48aeef 0%, #0098d0 100%);
    background: -webkit-linear-gradient(top,  #48aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,  #48aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48aeef', endColorstr='#0098d0',GradientType=0 );
    border-bottom:1px solid #0098d0;
}
ul.frontPageFilters ul.container2col .choisenCity {
    display:none;
    padding-top:2px;
}
ul.frontPageFilters ul.container2col .choisenCity div{
    position:relative;
    color:#FFF;
    background: #48aeef;
    background: -moz-linear-gradient(top,  #48aeef 0%, #0098d0 100%);
    background: -webkit-linear-gradient(top,  #48aeef 0%,#0098d0 100%);
    background: linear-gradient(to bottom,  #48aeef 0%,#0098d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48aeef', endColorstr='#0098d0',GradientType=0 );
    border-bottom:1px solid #0098d0;
    border-radius:5px;
    padding:0 5px;
    margin-top:3px;
}
ul.frontPageFilters ul.container2col .choisenCity div a{
    position:absolute;
    display:block;
    top:2px;
    right:2px;
    width:20px;
    line-height:24px;
    text-align:center;
    text-decoration:none;
    color:#FFF !important;
    font-family:Verdana;
    opacity:.8;
}
ul.frontPageFilters ul.container2col .choisenCity div a:hover{
    font-weight:bold;
    opacity:1;
}

#subMenu {
    display:none;
    max-width:980px;
    margin:0 auto;
    position:relative;
}
.myMenuType2 {
    position:absolute;
    z-index:1;
    top:0px;
    right:0px;
    box-shadow:0px 3px 10px #666;
    box-shadow:0px 3px 10px rgba(0,0,0,.75);
    background:#28942e;

    background: #4484b6;
    background: -moz-linear-gradient(top,  #4484b6 0%, #17487e 100%);
    background: -webkit-linear-gradient(top,  #4484b6 0%,#17487e 100%);
    background: linear-gradient(to bottom,  #4484b6 0%,#17487e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4484b6', endColorstr='#17487e',GradientType=0 );

    padding:12px 10px 20px;
    width:100%;
    max-width:250px;
    border-radius:5px;
    box-sizing:border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.myMenuType2 div.arrow{
    position:absolute;
    top:-13px;
    right:30px;
    width:25px;
    height:13px;
    background:url(/images/ico/subMenuArrowUP.png) 0 0 no-repeat;
}
.myMenuType2 .wellcome{
    display:block;
    width:100%;
    height:20px;
    padding-bottom:10px;
    border-bottom:1px solid #155284;
}
.myMenuType2 .wellcome span{
    float:left;
    font-size:15px;
    font-weight:bold;
    color:#FFF;
}
.myMenuType2 .wellcome a{
    display:inline-block;
    float:right;
    margin-top:2px;
}
.myMenuType2 .user{
    border-bottom:1px solid #184574;
    padding-bottom:5px;
}
.myMenuType2 .user ul{
    width:215px;
}
.myMenuType2 .user li.photo img{
    width:40px;
    box-shadow:2px 2px 5px #999;
}
.myMenuType2 .user li.name{
    font-size:13px;
    font-weight:bold;
    width:207px;
    color:#FFF;
}
.myMenuType2 .user li.name span{
    display:block;
    margin-top:2px;
    font-size:10px;
    font-weight:normal;
    color:#c5d7e7;
    line-height:16px;
}
.myMenuType2 .myItems{
    border-bottom:1px solid #5189b8;
}
.myMenuType2 .myItems > a, .myMenuType2 .myItems > span {
    display:block;
    line-height:30px;
    border-top:1px solid #5189b8;
    border-bottom:1px solid #184574;
    text-decoration:none !important;
    padding-left:26px;
    font-size:13px;
    background-image:url(/images/ico/userMenuArrow2.png);
    background-position:5px 108px;
    background-repeat:no-repeat;
    color:#FFF;
}
.myMenuType2 .myItems > a.selected, .myMenuType2 .myItems > span.selected {
    font-weight:bold;
    background-position: 5px -57px;
}
.myMenuType2 .myItems > a>span, .myMenuType2 .myItems > span>span{
    float:right;
    margin:-1px 5px 0 0;
    color:#c5d7e7;
    font-weight:normal;
    font-size:11px;
}
.myMenuType2 .myItems > a:hover , .myMenuType2 .myItems > span:hover{
    background-color:#fafafa;
    border-top:1px solid #fafafa;
    border-bottom:1px solid #fafafa;
    background-position:5px 8px;
    color:#000;
}
.myMenuType2 .myItems > a:hover > span , .myMenuType2 .myItems > span:hover > span{
    color:#1a609c;
}

.myMenu {
    position:relative;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #edece9 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%,#edece9 100%);
    background: linear-gradient(to bottom, #ffffff 0%,#edece9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edece9',GradientType=0 );
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
    color: #444;
    padding:12px 10px 20px;
    width:138px;
    border-radius:4px;
    margin-bottom:20px;
}
.myMenu .wellcome{
    display:block;
    width:138px;
    height:20px;
    padding-bottom:10px;
    border-bottom:1px solid #dddddd;
}
.myMenu .wellcome span{
    float:left;
    font-size:15px;
    font-weight:bold;
}
.myMenu .wellcome a{
    display:inline-block;
    float:right;
    margin-top:2px;
}
.myMenu .user{
    border-top:1px solid #FFF;
    border-bottom:1px solid #dddddd;
    padding-top:8px;
    padding-bottom:5px;
}
.myMenu .user ul{
    width:138px;
}
.myMenu .user li.name{
    font-size:13px;
    font-weight:bold;
    width:138px /*155*/
}
.myMenu .user li.name span{
    display:block;
    margin-top:2px;
    color:#777;
    font-size:10px;
    line-height:13px;
    font-weight:normal;
}
.myMenu .items{
    border-bottom:1px solid #FFF;
}
.myMenu .items a{
    display:block;
    line-height:30px;
    border-top:1px solid #FFF;
    border-bottom:1px solid #dddddd;
    text-decoration:none !important;
    font-size:13px;
    color:#004880;
}
.myMenu .items a.selected{
    font-weight:bold;
}
.myMenu .items a span{
    float:right;
    margin:-1px 5px 0 0;
    color:#5585aa;
    font-weight:normal;
    font-size:11px;
}
.myMenu .items a:hover{
    background-color:#fafafa;
}

.myLayout {
    margin-top:20px;
}
.myLayout .pageTitle{
    margin:0 0 15px;
}
.myLayout .pageTitle span.green{
    display:inline-block;
    margin:0 0 5px;
}
.myLayout .pageInfo{
    position:relative;
    display:inline-block;
    width:100%;
    padding:10px 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin:0 0 20px;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #edece9 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%,#edece9 100%);
    background: linear-gradient(to bottom, #ffffff 0%,#edece9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edece9',GradientType=0 );
    box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -webkit-box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    -o-box-shadow: 1px 2px 4px -2px #A7A7A7,-1px 2px 4px -2px #A7A7A7;
    border-radius: 4px;
    color: #444;
}
.myLayout .pageInfo .infTitle{
    font-size:15px;
    font-weight:bold;
}
.myLayout .pageInfo .adsType{
    position:absolute;
    top:5px;
    right:10px;
}
.myLayout .pageInfo .adsType a{
    text-decoration:none;
}
.myLayout .pageInfo .adsType a img{
    vertical-align:1px;
    margin-left:2px;
}

.myLayout .pageInfo .izvestiavane{
    position:absolute;
    top:5px;
    right:10px;
}
.myLayout .pageInfo .izvestiavane select{
    width:100%;
    margin-top:2px;
    height:22px;
}

.note_btn {
    width: 210px;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.note_btn img{
    vertical-align:-2px;
}

.act_btn {
    margin-right:145px;
    background:#1B8E00;
    border-color:#1B8E00;
    width: 150px;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.act_btn img{
    vertical-align:-2px;
}
.act_btn:active {
    background:#177800;
    border-color:#177800;
}

.block_btn {
    margin-right:145px;
    background:#CCC;
    border-color:#CCC;
    width: 150px;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.block_btn img{
    vertical-align:-2px;
}
.block_btn:active {
    background:#BBB;
    border-color:#BBB;
}

.del_btn {
    background:#F00;
    border-color:#F00;
    width: 135px;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.del_btn img{
    vertical-align:-2px;
}
.del_btn:active {
    background:#D00;
    border-color:#D00;
}


.promo_btn {
    margin-right:455px;
    width: 140px;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: green;
    border: 1px solid green;
}

.edit_btn {
    margin-right:305px;
    width: 140px;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.edit_btn img{
    vertical-align:-2px;
}

.filter{
    position:relative;
    width:800px;
    background:#FFF;
    margin:0 0 20px;
    padding:12px 12px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #A7A7A7;
    -webkit-box-shadow: 0px 0px 6px 0px #A7A7A7;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.filter:hover{
     box-shadow: 0px 0px 12px 0px #666;
    -webkit-box-shadow: 0px 0px 15px 0px #666;
    transition:all .4s;
}
.filter div.date{
    font-size:15px;
    font-weight:bold;
    margin-bottom:10px;
}
.filter div.date div{
    float:right;
    font-weight:normal;
    margin-bottom:10px;
}
.filter div.date div a{
    font-weight:bold;
}
.filter div.date div a.delete{
    color:#E00;
}
.filter input[type="text"]{
    width:545px;
    height:26px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding:0 5px;
    font-weight:bold;
    vertical-align:middle;
    border:1px solid #e7e0c4;
    border-right:0;
    background:#f9edbe;
}
.filter button.save{
    width:auto;
    height:26px;
    line-height:normal;
    border-radius:0 4px 4px 0;
    vertical-align:middle;
}
.filter span.fltrLab {
    display:inline-block;
    width:120px;
    font-weight:bold;
}
.filter .info {
    margin-top:8px;
    line-height:18px;
    color:#666;
}
.filter .info strong{
    color:#333;
}
.filter .controls {
    margin-top:10px;
    color: #444;
    border-radius:4px;
    height: 25px;
}

.filter .controls select{
    border:1px solid #a9a9a9;
    height:26px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align:middle;
}
.filter .controls .izvStart{
    width:auto;
    line-height:24px;
    font-size:11px;
    vertical-align:middle;
}
.filter .controls .izvStop{
    width:auto;
    line-height:24px;
    font-size:11px;
    vertical-align:middle;

    background-color:#FFF;
    border-color:#DDD;
    color:#999;
}
.filter .controls .izvStop:active{
    color:#666;
}
.filter .controls .startFilter{
    float:right;
    margin-right:-5px;
    width:250px;
    line-height:24px;
    font-size:15px;
    vertical-align:middle;
    background-color:#F00;
    border-color:#F00;
}
.filter .controls .startFilter:active{
    background-color:#D00;
    border-color:#D00;
}





.myPortfeil{
    position:relative;
    width:800px;
    background:#FFF;
    margin:0 0 20px;
    padding:12px 12px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #A7A7A7;
    -webkit-box-shadow: 0px 0px 6px 0px #A7A7A7;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.settings{
    font-size:14px;
}
.settings div.title{
    font-size:15px;
    font-weight:bold;
    margin-bottom:15px;
}
.myPortfeil .sumLab {
    display:inline-block;
    width:60px;
}
.myPortfeil .sumForm {
    height:30px;
}
ul.userSettings{
    position:relative;
    width:770px;
}
ul.userSettings li.left{
    width:400px;
    margin-right:20px;
}
ul.userSettings li.left ul{
    width:400px;
}
ul.userSettings li.left li.lab{
    float:left;
    width:130px;
    padding-top:2px;
    line-height:11px;
}
ul.userSettings li.left li{
    float:left;
    width:240px;
    margin-bottom:10px;
}
ul.userSettings li.left input[type="text"],
ul.userSettings li.left input[type="password"]
{
    width:240px;
    padding:0 10px;
    height:25px;
}
ul.userSettings li.right{
    width:340px;
}
ul.userSettings button{
    position:absolute;
    bottom:12px;
    right:0;
    margin:0;
    width:340px;
}

.totalSum{
    float:right;
    display:inline-block;
    font-size:28px;
    font-weight:normal;
}
.sumItem{
    display:inline-block;
    width:100%;
    border-bottom:1px solid #DDD;
    padding-bottom:13px;
    margin-bottom:10px;
}
.sumItem span.date{
    float:left;
    color:#999;
    line-height:18px;
}
.sumItem span.sum{
    float:right;
    display:inline-block;
    font-size:15px;
    line-height:18px;
    text-align:right;
}
.titleBig{
    font-size:21px;
    font-weight:bold;
}
.upLine{
    border-top:15px solid #e9e9e9;
    margin:25px 0;
}
.addBelejnikFilterbtn {
    background:#F60;
    border:1px solid #F60;
}
.addBelejnikFilterbtn:active {
    background:#F80;
    border:1px solid #F80;
}
.citysvg svg path, .regionsvg svg path, .bgsvg svg path {
    stroke: #ffffff;
    stroke-width: 1;
}
.citysvg svg circle, .regionsvg svg circle, .bgsvg svg circle {
    stroke: #777;
    stroke-width: 1;
}

.spisakRaioni {
    position:absolute;
    top:186px;
    right:0;
    line-height:16px;
    max-height: 508px;
    overflow-x: hidden;
    overflow-y: auto;
    width:115px;
}
.spisakRaioni div{
    cursor:default;
}
.spisakRaioni div:hover{
    transition:color .1s;
    color:#BBB;
}
.spisakRaioni span{
    width:100px;
    display:inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
}
.spisakRaioni a{
    display:block;
    width:15px;
    float:left;
    text-decoration:none;
    font-family:verdana;
}
.spisakRaioni a:hover{
    color:#F00;
    font-weight:bold;
}
.advTopFilters {
    width: 940px;
    border-collapse: collapse;
    border: none;
    margin: 0 0 15px !important;
    padding: 0;
}
.advTopFilters td.L{
    width:440px;
    padding-right:15px;
}
.advTopFilters td.R{
    width:470px;
}


.advTopFilters .properyType,
#properyType {
    width:440px;
    border-collapse:collapse;
    border:0;
}
#properyType {
    width:450px;
}
.advTopFilters .properyType td,
#properyType td{
    vertical-align:top;
    font-family:Tahoma;
    font-size:13px;
    color:#333;
    width:140px;
}
.advTopFilters .properyType td.title,
#properyType td.title{
    width:100%;
    font-weight:bold;
    font-size:14px;
    padding-top:3px;
    padding-bottom:3px;
}
.advTopFilters .properyType td.colsep,
#properyType td.colsep{
    width:10px;
}
.advTopFilters .properyType label,
#properyType label{
    margin:0 !important;
    line-height:23px;
}
.advTopFilters .properyType input,
#properyType input{
    margin:0 3px 0 3px;
    padding:0;
    vertical-align:-1px;
}
.propertyOptions {
    width:480px !important;
    border-collapse:collapse;
    border:0;
}
.propertyOptions td.left{
    width:185px;
}
.propertyOptions td.right{
    width:185px;
}
.propertyOptions td.end{
    width:70px;
}
.propertyOptions td.space{
    width:20px;
}
.propertyOptions td.whole{
    width:275px;
}
.propertyOptions .formWrapper{
    float:left;
    width:87px;
}
.propertyOptions td.end .formWrapper{
    width:70px !important;
}
.propertyOptions td.whole .formWrapper{
    width:275px !important;
}
.propertyOptions .formWrapper span{
    width:auto;
}
.propertyOptions select{
    width:60px;
    float:left
}
.propertyOptions span{
    float:left;
    display:inline-block;
    width:10px;
    line-height:30px;
    text-align:center;
}
.checkbox_wrapper{
    padding:1px;
    font-size:11px;
    font-family:Verdana,Geneva,sans-serif;
}
.checkboxes li{
    width:33%;
}
.checkboxes_25 li{
    width:25%;
}
.checkboxes.error{
border:#F00 1px solid;
}
/* UPLOAD START*/
.imgplupload #selectfiles {
    background: url("/images/no_photo_med.jpg") no-repeat scroll center center / 105px auto transparent;
    border: 1px solid #d4d4d4;
    color: #000;
    display: block;
    float: left;
    height: 85px;
    padding: 5px 0 0 5px;
    text-align: center;
    width: 112px;
}

.imgplupload #selectfiles span {
    top: 20px;
    position: relative;
}

.imgplupload #container{
  height: 100px;
}

.imgplupload #selectfiles div{
    font-weight: bold;
    margin-top: 22px;
}

.imgplupload .filelist .preview {
    border: 1px solid #d4d4d4;
    color: #000;
    cursor: move;
    display: block;
    float: left;
    height: 85px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 5px 0 0 5px;
    position: relative;
    width: 112px;
}

.imgplupload .filelist .preview.imgplupload_error {
  border: 1px solid red !important;
}

.imgplupload .filelist .preview .img {
  width: 105px;
  height: 78px;
  background: url("/images/no_photo_med.jpg") no-repeat scroll center center #fff;
  background-size: 105px auto
}

.imgplupload .filelist .preview .processing {
  width: 16px;
  height: 16px;
  background: url("/images/uplloader.gif") no-repeat scroll center center transparent;
  left: 49px;
  position: absolute;
  top: 35px;
}


.imgplupload .delete-pic {
  background: url("/images/upldel.gif") no-repeat scroll 0 0 transparent;
  height: 16px;
  right: -8px;
  top: -8px;
  width: 16px;
  overflow: hidden;
  text-indent: -1000em;
  position: absolute;
}


.imgplupload div.main {
    background-color: #000000;
    color: #FFFFFF;
    height: 17px;
    margin-left: 1px;
    margin-right: 1px;
    opacity: 0.7;
    position: absolute;
    text-align: center;
    top: 67px;
    left: 6px;
    width: 103px;
}
/* UPLOAD END*/

.phonePopup {
	position:relative;
	/*bottom:-53px;*/
	left:0;
	margin-top:13px;
	max-width:300px;
	background:#c4f0ff;
	border:1px solid #999;
	border-radius:4px;
	padding:5px 7px;
	font-weight:normal !important;
	color:#333;
	font-size:11px;
	line-height:14px;
	overflow:visible !important;
	text-overflow:clip !important;
	white-space:normal !important;
	box-shadow:2px 2px 3px #666;
}
.phonePopup img{
	position:absolute;
	top:-10px;
	left:20px;
}

.formerrmsg{
color:#F00;
margin-top:3px;
}

.statusShareBox {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #287f00;
    color: #287f00;
    display: none;
    font-weight: 700;
    height: 23px;
    line-height: 23px;
    margin-top: 150px;
    overflow: hidden;
    padding: 0 7px;
    text-align: center;

}

.mapAgencyAddress {
    width:760px;
    margin:0 auto 20px;
    border-collapse:collapse;
    line-height:normal;
    margin-top:10px;
}
.mapAgencyAddress div.buttons{
    margin-top:10px;
    display:inline-block;
    width:100%;
}
.mapAgencyAddress .agencyButton{
    width:200px;
    line-height:26px;
    margin-bottom:15px;
    border: 1px solid #AFAFAF;
    border-radius: 3px 3px 3px 3px;
    -webkit-box-shadow: 0 1px 0 white;
    -o-box-shadow: 0 1px 0 white;
    box-shadow: 0 1px 0 white;
    color: black;
    font-size: 14px;
    text-decoration:none;
    text-align:center;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(255,255,255,1)),color-stop(100%,rgba(237,236,233,1)));
    background: -webkit-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: -o-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: -ms-linear-gradient(top,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    background: linear-gradient(to bottom,rgba(255,255,255,1) 0%,rgba(237,236,233,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff',endColorstr='#edece9',GradientType=0 );
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.mapAgencyAddress button{
    float:right;
    width:200px;
    line-height:26px;
}
.mapAgencyAddress button img{
    vertical-align:0px;
    margin-left:7px;
}
.mapAgencyAddress td{
    vertical-align:top;
}
.mapAgencyAddress td.logo{
    width:155px;
    line-height:0;
}
.mapAgencyAddress td.logo a{
    display: inline-block;
    padding: 2px;
    background: #FFF;
    text-align: center;
    border: 1px solid #e6e6e6;
}
.mapAgencyAddress td.info{
    width:400px;
    padding-right:10px;
    line-height:14px;
}
.mapAgencyAddress td.info span.phones{
    display:inline-block;
    font-size:15px;
    line-height:24px;
}
.mapAgencyAddress td.info strong{
    display:inline-block;
    margin-bottom:8px;
}
.mapAgencyAddress td.buttons{
    text-align:right;
}
.banner-megabord {
    margin: 0px auto 0;
    width: 100%;
    display: block;
    text-align: center;
}

/* LOG */
.newLogin {
    background: #FFF;
    width: 820px;
    padding: 20px;
    margin: 50px auto;
}
.newLogin .formsWrapper {
    clear: both;
    border: 2px solid #DDD;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 20px 20px 27px 20px;
    margin-top: -3px;
}
.newLogin a.loginButton {
    /*float: right;*/
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 5px;
    line-height: 44px;
    padding: 0 15px;
    background: #09F;
    color: #FFF;
    font-size: 18px;
    border-radius: 5px;
    text-decoration: none;
    transition: background .1s;
    font-weight: bold;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.newLogin a.loginButton:hover {
    background: #40b3ff;
}
.newLogin a.loginButtonWhite {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 5px;
    line-height: 42px;
    padding: 0 15px;
    background: #FFF;
    color: #09F;
    font-size: 18px;
    border: #09F 1px solid;
    border-radius: 5px;
    text-decoration: none;
    transition: background .1s;
    font-weight: bold;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.newLogin a.loginButtonWhite:hover {
    color: #aa2527;
}
.logPopup {
    display: none;
    position: fixed;
    z-index: 999999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
}
.logPopupFrame {
    display:block;
    width:860px;
    height:550px;
    margin:auto;
    margin-top:5%;
}

.clever-link, [data-link] {
    cursor: pointer;
}

/*.breadcrumb {*/
/*    height: 24px;*/
/*    line-height: 24px;*/
/*}*/

/*.breadcrumb img {*/
/*    vertical-align: middle;*/
/*}*/

/*.breadcrumb a {*/
/*    text-decoration: none;*/
/*}*/

/*.breadcrumb span {}*/

/*.breadcrumb span::after {*/
/*    content: ' \27A4';*/
/*    color: '#004880';*/
/*}*/

.breadcrumb span:last-child::after {
    content: '';
}


.breadcrumbsWrapper {
    height: 32px;
    position: relative;
    margin-bottom: 20px;
}
.breadcrumbs{
    position: absolute;
    z-index: 999;
    display: inline-block;
    /*border: 1px solid #DDD;
    border-right: none;*/
    border-left: 1px solid #DDD;
    background-color: white;
}
.breadcrumbs a , .breadcrumbs .menuWrapper>span{
    position: relative;
    display: inline-block;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    line-height: 32px;
    padding-right: 8px;
    padding-left: 16px;
    transition: color .3s;
    border-right: 1px solid #FFF;
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}
.breadcrumbs a:hover {
    color: #f5791b;
}
.breadcrumbs .menuWrapper:hover a.withMenu{
    border-right-color: #DDD;
    border-bottom: 1px solid #FFF;
    height: 32px;
    color: #f5791b;
}
.breadcrumbs .menuWrapper:hover a.withMenu b{
    display: none;
}
.breadcrumbs .menuWrapper:hover a i{
    transform: rotate(-180deg);
}
.breadcrumbs .menuWrapper:hover .bcMenu{
    display: block;
}
.breadcrumbs a i{
    display: inline-block;
    margin-left: 10px;
    line-height: 0;
    width: 11px;
    height: 5px;
    background: url(/images/ico/breadcrumbs_down.svg) 0 0 no-repeat;
    transition: transform .2s;
}
.breadcrumbs a b,.breadcrumbs .menuWrapper>span b {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: -9px;
    background: url(/images/ico/breadcrumbs_next.svg) 0 0 no-repeat;
    width: 8px;
    height: 33px;
}
.breadcrumbs a.homeIcon {
    display: inline-block;
    width: 16px;
    height: 32px;
    vertical-align: middle;
    background: url(/images/ico/home.svg) 8px 8px no-repeat;
    padding-right: 2px;
    padding-left: 10px;
    vertical-align: top;
    float: left;
}
.breadcrumbs a.homeIcon:hover {
    background-position: 8px -22px;
    transition: background-position .3s;
}
.breadcrumbs .menuWrapper {
    float: left;
    position: relative;
}
.breadcrumbs .menuWrapper .bcMenu{
    display: none;
    position: absolute;
    z-index: -999;
    left: 0;
    top: 33px;
    background: #FFF;
    border: 1px solid #DDD;
    padding-bottom: 10px;
}
.breadcrumbs .menuWrapper .bcMenu a{
    display: block;
    white-space: nowrap;
    border: none;
    transition: background .3s, color .3s;
    padding-right: 30px;
    line-height: 30px;
}
.breadcrumbs .menuWrapper .bcMenu a:hover{
    background: #e4ebf0; /* ced8e3 */
    /*color: #333;*/
}
.breadcrumbs .menuWrapper .bcMenu a span{
    color: #AAA;
}

.h1Results {
    display: inline-block; font-size: 19px; font-weight: bold;
}
.h1Results::first-letter {
    text-transform: capitalize;
}

.seo {
    margin: 10px 0;
}
.seo textarea {
    height: 300px;
}
.seo button.btn {
    width: 100px;
}
.hidden {
    display: none;
}
a.callBTN {
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    padding: 0 8px;
    background: #00a651;
    border-radius: 5px;
    line-height: 32px;
    text-align: left;
    color: #FFF;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
a.callBTN:hover {
    background: #008f46;
    text-decoration: none;
    color: #FFF;
}
a.callBTN span {
    font-size: 12px !important;
    line-height: 12px;
    font-weight: normal;
    opacity: .7;
}
a.callBTN img {
    height: 18px;
    vertical-align: -4px;
    margin-right: 3px;
}

a.callBTN2 {
    width: 200px;
    padding: 0 8px;
    box-sizing: border-box;
    background: #00a651;
    border-radius: 5px;
    line-height: 32px;
    text-align: left;
    color: #FFF;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
.adThumbContainer {
    display: flex;
    margin-top: 10px;
}

.adThumbContainer .adThumb {
    position: relative;
    padding: 5px 5px;
    width: 150px;
    height: 200px;
    margin-right: 10px;
    display: block;
    background-color: white;
    border-radius: 4px; box-shadow: 2px 2px 4px -2px #A7A7A7,-2px 2px 4px -2px #A7A7A7;
    overflow: hidden;
}
.adThumbContainer .adThumb:last-child {
    margin-right: 0px;
}

.adThumbContainer .adThumb .price {
    color: #c00; font-weight: bold;
}

.adThumbContainer .adThumb .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    color: #F70; font-weight: bold;
}

.adThumbContainer .adThumb a {
    position: absolute ;
    top: 0; bottom:0; left:0; right: 0
}

.adThumbContainer .adThumb img {
    width: 100%;
    object-fit: cover;
    height: 110px;
}

.seoLinks {
    position:relative;
    display:inline-block;
    width: 980px;
    margin:0 0 16px;
    padding:7px 12px;
    background: #FFF;
    border-radius: 4px;
    color:#444;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.seoLinks ul {
    display: inline-block;
    width: 100%;
    list-style: disc inside;
    margin: 0;
    padding: 0;
}
.seoLinks li {
    float: left;
    width: 50%;
}
.seoLinks li {
    float: left;
    width: 50%;
}
.seoLinks div{
    font-weight: bold;
    margin-bottom: 8px;
}



ul.questionForms {
    width: 100%;
    display: inline-block;
    list-style: none;
    margin: 20px 0 0;
    padding: 0 20px;
}
ul.questionForms li.data{
    float: left;
    width: 40%;
    padding-right: 30px;
    box-sizing: border-box;
}
ul.questionForms li.message{
    float: left;
    width: 60%;
}
ul.questionForms label{
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: bold;
}
ul.questionForms textarea{
    width: 100%;
    padding: 5px 10px;
}
ul.questionForms input[type="text"], ul.questionForms input[type="email"], ul.questionForms input[type="tel"]{
    width: 100%;
    height: 28px;
    padding: 0 10px;
    margin-bottom: 10px;
}
ul.questionForms input[type="text"]:last-child, ul.questionForms input[type="email"]:last-child,ul.questionForms input[type="tel"]:last-child {
    margin-bottom: 0;
}
ul.questionForms .buttons {
    margin-top: 10px;
}
ul.questionForms .buttons button {
    margin-top: 15px;
}
.tipPortfeil {
    margin-top: 15px;
    padding: 15px 20px;
    border: 1px solid #0b9a0060;
    border-radius: 10px;
    color: #0B9A00;
    font-size: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACE0lEQVRYhd2X0W3jMAyG/0eFfMkIGcEb1CNkg/MI2aDeoH0LSD/IGzQbxBtcNrhs4Gyge6jkk205ba+nGDgBBAxBoD5S5C8Z+OZgRcWKnhU9Kaw5Yvddn3cHCWpWnE2D0hyxY4VL2JkFP/7JhqZBSQoLDNE6FtxgsSVBuwDgWOFI8IsaPMNi+3ebv0fYk+DiATrvuIXF1qd+vrHimpizrCi+BECCvd+w9gCOFc6DVQubn/zaYiFD509nxJ+3Mw3KAMOKDgBClKS4kuLEim4jOEx9hKzFllqXHKx4ZYWLv1lRmQZl5LD6wMcMgATtZwG6kFISXFhw899tiP4jH6ljIME+udg0KI3iKfQyKa4bwcEXnCNBHbdeqA0S7Enx08/3pLDhnM0ROxbc4gJNRfo6Iaz9vGNFEQrOHLELdcEK51txP40wgAzFZrGNIKppiupEiupAHtI4VLd3FM7RF+CSDrQ+kCLWjzHAuGfHdI8Y95Qsg51Ng3JNgGDFqgAjHVgpA93/D0CKKwlaEtRT47jbMp1xDbyroFE8pWzQg1yb80RdExk6ZQEA5upKgstiHeQoLo6u4HDzJSQ/H0AccVA+nr+gsgD0/oaMo+1J8Mbz92MWgD9vhDu3JOcsQlY4Frx434VpUKYsWxuOUq+w1OA5ZWtLsVv3NozfhQsikRvgNABsBIdHA8xeRRvB4RGZIMFl+k/wGwmIvL/QRbNbAAAAAElFTkSuQmCC) 10px center no-repeat;
    padding-left: 55px;
}
.tipPortfeil button {
    float: right;
    margin: -7px -12px 0 0;
    padding: 0 20px;
    color: #0B9A00;
    border: none;
    border-radius: 5px;
    white-space: nowrap;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    display: inline-block;
    width: auto;
    height: 34px;
    cursor: pointer;
    background: #e5ede5;
    vertical-align: bottom;
}
.tipPortfeil button:hover {
    color: #FFF;
    background: #0B9A00;
}


.tipOptions {
    margin: 20px 0;
}
.tipOptions label {
    font-size: 15px;
    font-weight: bold;
}
.tipOptions div {
    margin-top: 5px;
    margin-bottom: 20px;
    color: #666;
    font-size: 14px;
    font-style: italic;
    font-family: Arial;
    padding-left: 27px;
}
.tipOptions label span {
    font-weight: normal;
    font-style: italic;
    font-family: Arial;
}

a.btBlue, button.btBlue {
    padding: 7px 26px 6px 8px;
    color: #fff;
    border: none;
    border-radius: 5px;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    display: inline-block;
    width: auto;
    cursor: pointer;
    background: #38618c;
    vertical-align: bottom;
}

a.news {
    text-decoration: none;
    color: #2a2a2a;
}
